import React from "react"
import { DecorativeDots } from "gatsby-interface"
import styled from "@emotion/styled"

import { Sizes, DotSizes } from "./constants"

const Wrapper = styled.div`
  position: absolute;
  z-index: -1;
  top: 25vh;
  opacity: 0.2;

  ${p => p.theme.mediaQueries.tablet} {
    opacity: 0.6;
  }

  ${p => p.theme.mediaQueries.desktop} {
    opacity: 1;
  }
`

const ShiftedWrapper = styled(Wrapper)`
  transform: translateY(-25%);
`

const HeroDots = () => {
  // DecorativeDots produces random values, which can mess up snapshot tests
  // which expect the same output every time.
  // Because this component is decorative anyway, not core functionality, we
  // don't need to worry about testing it.
  if (process.env.NODE_ENV === `test`) {
    return null
  }

  return (
    <>
      <Wrapper style={{ left: 0 }}>
        <DecorativeDots
          width={350}
          height={200}
          dotSize={DotSizes[Sizes.Large]}
          angle={180}
          fadeStrength={0.2}
        />
      </Wrapper>
      <ShiftedWrapper style={{ right: 0 }}>
        <DecorativeDots
          width={325}
          height={250}
          dotSize={DotSizes[Sizes.Large]}
          angle={0}
          fadeStrength={0.15}
        />
      </ShiftedWrapper>
    </>
  )
}

export default HeroDots
