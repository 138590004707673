import styled from "@emotion/styled"

const isProduction = process.env.NODE_ENV === `production`

const createDeprecationCss = (deprecationMessage = `deprecated`) => theme => ({
  border: `2px solid red !important`,
  "&:hover": {
    "&::before": {
      content: `"${deprecationMessage}"`,
      position: `absolute`,
      transform: `translate3d(0, calc(-100% - 2px), 0)`,
      background: `white`,
      color: `red`,
      fontSize: theme.fontSizes[0],
      fontWeight: theme.fontWeights.body,
      fontFamily: theme.fonts.body,
    },
  },
})

const headerDeprecationCss = createDeprecationCss(
  `Deprecated, use Header from gatsby-interface`
)
const textDeprecationCss = createDeprecationCss(
  `Deprecated, use Text from gatsby-interface`
)

export const SiteHeading = styled(`h1`)(props => [
  {
    color: props.theme.colors.grey[90],
    fontFamily: props.theme.fonts.heading,
    fontSize: props.theme.fontSizes[5],
    margin: 0,

    strong: {
      color: props.theme.colors.lilac,
    },
  },
  !isProduction && headerDeprecationCss(props.theme),
])

export const EmphaticHeading = styled(`h2`)(props => [
  {
    color: props.theme.colors.grey[90],
    fontFamily: props.theme.fonts.heading,
    fontSize: props.theme.fontSizes[7],
    fontWeight: 800,
    letterSpacing: `-0.02em`,
    lineHeight: 1,
    margin: 0,

    [props.theme.mediaQueries.desktop]: {
      fontSize: props.theme.fontSizes[9],
    },
  },
  !isProduction && headerDeprecationCss(props.theme),
])

export const Heading = styled(`h2`)(props => [
  {
    color: props.theme.colors.grey[90],
    fontFamily: props.theme.fonts.heading,
    fontSize: props.theme.fontSizes[5],
    margin: 0,

    strong: {
      color: props.theme.colors.lilac,
    },

    small: {
      color: props.theme.colors.grey[50],
      display: `block`,
      fontSize: props.theme.fontSizes[1],
      fontWeight: `normal`,
      letterSpacing: `0.03em`,
      marginBottom: props.theme.space[3],
      textTransform: `uppercase`,
    },
  },
  !isProduction && headerDeprecationCss(props.theme),
])

export const Subheading = styled(`h3`)(props => [
  {
    color: props.theme.colors.grey[90],
    fontFamily: props.theme.fonts.heading,
    fontSize: props.theme.fontSizes[3],
    margin: `1.5rem 0 0`,

    small: {
      color: props.theme.colors.grey[50],
      display: `block`,
      fontSize: props.theme.fontSizes[0],
      fontWeight: `normal`,
      letterSpacing: `0.03em`,
      marginBottom: props.theme.space[3],
      textTransform: `uppercase`,
    },
  },
  !isProduction && headerDeprecationCss(props.theme),
])

export const Lede = styled(`p`)(props => [
  {
    color: props.theme.colors.grey[70],
    fontFamily: props.theme.fonts.system,
    fontSize: props.theme.fontSizes[3],
    margin: `0.5rem 0 0`,
  },
  !isProduction && textDeprecationCss(props.theme),
])

export const Text = styled(`p`)(props => [
  {
    color: props.theme.colors.grey[70],
    fontFamily: props.theme.fonts.system,
    fontSize: props.theme.fontSizes[2],
    lineHeight: 1.5,
    margin: `1rem 0 0`,
  },
  !isProduction && textDeprecationCss(props.theme),
])

export const ErrorText = styled(Text)(props => [
  {
    fontWeight: `bold`,
    svg: {
      marginRight: props.theme.space[3],
      verticalAlign: `middle`,
      marginTop: `-0.1rem`,
      fill: props.theme.colors.red[50],
    },
  },
  !isProduction && textDeprecationCss(props.theme),
])
