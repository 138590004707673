import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { LinkButton } from "gatsby-interface"
import HeroDots from "../../components/decoration/dots/HeroDots"
import { useTracker } from "../../utils/analytics"
import { MdArrowForward, MdChatBubbleOutline } from "react-icons/md"
import Layout from "../../layouts"
import SEO from "../../components/seo"
import Header from "../../components/header"
import {
  EmphaticHeading,
  Heading,
  Text as BaseText,
  Lede as BaseLede,
} from "../../components/typography"
import { NarrowSingleColumn } from "../../layouts/Containers"
import {
  Author,
  Credits,
  Quote,
  Position,
  Sign,
  Testimonial,
  Testimonials,
  TestimonialsBlock,
} from "./Testimonials"
import { Avatar } from "gatsby-interface"

const PageHeader = styled(`header`)`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${p => p.theme.space[12]} 0 0;
  position: relative;
  z-index: 1;

  ${Heading} {
    font-size: ${p => p.theme.fontSizes[6]};
    position: relative;
  }

  p {
    margin: 0;
  }

  ${EmphaticHeading} {
    font-size: ${p => p.theme.fontSizes[10]};
    margin-top: ${p => p.theme.space[7]};
    position: relative;
    text-align: center;
  }
`

const Note = styled(`div`)`
  color: ${p => p.theme.colors.grey[60]};
  font-style: italic;
  font-size: ${p => p.theme.fontSizes[1]};
  margin: ${p => p.theme.space[8]} auto ${p => p.theme.space[5]};
  max-width: 100%;
  padding: 0 ${p => p.theme.space[7]};
  text-align: center;
  width: 28rem;

  p {
    margin: 0;
  }
`

const Logos = styled(`div`)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 ${p => p.theme.space[7]};

  p {
    margin: 0;
  }

  img {
    height: ${p => p.theme.space[5]};
    margin: ${p => p.theme.space[4]};
    margin-bottom: 0;
    width: auto;
  }
`

const Body = styled(NarrowSingleColumn)`
  padding-bottom: ${p => p.theme.space[9]};
  padding-top: ${p => p.theme.space[9]};
`

const Lede = styled(BaseLede.withComponent(`div`))`
  font-family: ${p =>
    p.theme.fonts.heading} !important; /* todo: investigate the hack */
  font-weight: bold;
  font-size: ${p => p.theme.fontSizes[4]};
  line-height: 1.25;
  margin-bottom: ${p => p.theme.space[8]};
`

const Text = styled(BaseText.withComponent(`div`))`
  p {
    margin: 0;
  }
`

const Links = styled(`ul`)`
  line-height: 1.4;
  list-style: none;
  margin: ${p => p.theme.space[10]} 0 ${p => p.theme.space[15]};

  li {
    margin-bottom: ${p => p.theme.space[3]};
  }

  a {
    font-weight: bold;
    text-decoration: none;
  }

  svg {
    vertical-align: text-top;
  }

  ${p => p.theme.mediaQueries.desktop} {
    li {
      margin-bottom: ${p => p.theme.space[2]};
    }
  }
`

const buttonCss = theme => ({
  background: theme.colors.yellow[50],
  color: theme.colors.grey[90],
  marginTop: theme.space[8],
  position: "relative",

  ":hover": {
    background: theme.colors.purple[60],
    color: theme.colors.yellow[50],
  },
})

const PreviewLanding = ({ data, location }) => {
  const { trackButtonClicked } = useTracker()
  const { contentfulPage } = data
  const {
    title,
    subtitle: { subtitle },
    quickLinks,
    sentenceLinks,
    testimonialsBlock,
    contentBlocks,
  } = contentfulPage

  const { url: ctaUrl, displayTitle: ctaLabel } = quickLinks[0]

  const contentBlocksHTML = contentBlocks.reduce((blocks, block) => {
    blocks[block.contentfulid] = block.body.childMarkdownRemark.html
    return blocks
  }, {})

  return (
    <Layout pathname={location.pathname}>
      <SEO contentfulPage={contentfulPage} url={location.href} />
      <Header />
      <PageHeader>
        <HeroDots />
        <Heading>{title}</Heading>
        <EmphaticHeading
          dangerouslySetInnerHTML={{
            __html: subtitle.replace(/(?:\r\n|\r|\n)/g, `<br />`),
          }}
        />
        <LinkButton
          css={buttonCss}
          size={`XL`}
          to={ctaUrl}
          onClick={() =>
            trackButtonClicked(`Start 14-day free trial`, {
              uiSource: `Preview landingpage`,
            })
          }
          rightIcon={<MdArrowForward />}
        >
          {ctaLabel}
        </LinkButton>
        <Note
          dangerouslySetInnerHTML={{
            __html: contentBlocksHTML.previewLanding_ctaNote,
          }}
        />
        <Logos
          dangerouslySetInnerHTML={{
            __html: contentBlocksHTML.previewLanding_logos,
          }}
        />
      </PageHeader>
      <Body>
        <Lede
          dangerouslySetInnerHTML={{
            __html: contentBlocksHTML.previewLanding_lede,
          }}
        />
        <Text
          dangerouslySetInnerHTML={{
            __html: contentBlocksHTML.previewLanding_text,
          }}
        />
      </Body>
      {testimonialsBlock && (
        <TestimonialsBlock>
          <Sign>
            <MdChatBubbleOutline />
          </Sign>
          <Heading>{testimonialsBlock.title}</Heading>
          <Testimonials>
            {testimonialsBlock.testimonials.map((testimonial, idx) => {
              const {
                author,
                url,
                quote: {
                  childMarkdownRemark: { html: quoteHtml },
                },
                position: {
                  childMarkdownRemark: { html: positionHtml },
                },
                photo: {
                  fixed: { src },
                },
              } = testimonial

              return (
                <Testimonial
                  key={idx}
                  style={{
                    flexBasis: `${90 / testimonialsBlock.testimonials.length}%`,
                  }}
                >
                  <Quote
                    dangerouslySetInnerHTML={{
                      __html: quoteHtml,
                    }}
                  />
                  <Credits href={url}>
                    <Avatar
                      src={src}
                      label=""
                      css={theme => ({
                        marginRight: theme.space[4],
                      })}
                      size="L"
                    />
                    <Author>
                      {author}
                      <Position
                        dangerouslySetInnerHTML={{
                          __html: positionHtml.replace(
                            /(?:\r\n|\r|\n)/g,
                            `<br />`
                          ),
                        }}
                      />
                    </Author>
                  </Credits>
                </Testimonial>
              )
            })}
          </Testimonials>
        </TestimonialsBlock>
      )}
      <NarrowSingleColumn>
        <Links>
          {sentenceLinks.map(item => {
            const { sentence, anchorText, url } = item
            const outerLink = url.includes(`http`)
            return (
              <li key={anchorText}>
                {sentence}
                {` `}
                {!outerLink ? (
                  <Link to={`/${url}`}>
                    {anchorText} <MdArrowForward />
                  </Link>
                ) : (
                  <a href={url}>
                    {anchorText} <MdArrowForward />
                  </a>
                )}
              </li>
            )
          })}
        </Links>
      </NarrowSingleColumn>
    </Layout>
  )
}

PreviewLanding.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default PreviewLanding

export const pageQuery = graphql`
  query ContentfulPreviewLanding($id: String!) {
    contentfulPage(id: { eq: $id }) {
      name
      title
      subtitle {
        subtitle
      }
      quickLinks {
        displayTitle
        url
      }
      sentenceLinks {
        sentence
        anchorText
        url
      }
      ...SocialMediaImage
      testimonialsBlock {
        title
        testimonials {
          author
          url
          quote {
            childMarkdownRemark {
              html
            }
          }
          position {
            childMarkdownRemark {
              html
            }
          }
          photo {
            fixed(width: 150, height: 150) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
      ...ContentBlocks
    }
  }
`
