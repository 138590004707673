import styled from "@emotion/styled"

const dimensions = {
  pageNav: {
    minHeight: `5rem`,
  },
  pagePadding: {
    mobile: `1.4rem`,
    tablet: `2rem`,
  },
}

export const StandardSingleColumn = styled.div`
  margin: 0 auto;
  max-width: 64rem;
  padding-left: ${dimensions.pagePadding.mobile};
  padding-right: ${dimensions.pagePadding.mobile};

  ${p => p.theme.mediaQueries.tablet} {
    padding-left: ${dimensions.pagePadding.tablet};
    padding-right: ${dimensions.pagePadding.tablet};
  }
`

export const NarrowSingleColumn = styled(StandardSingleColumn)`
  margin: ${p => p.theme.space[8]} auto;
  max-width: 46rem;
`
