import styled from "@emotion/styled"
import { StandardSingleColumn } from "../../layouts/Containers"
import { Heading } from "../../components/typography"

export const TestimonialsBlock = styled(StandardSingleColumn)`
  margin: ${p => p.theme.space[8]} auto;
  position: relative;
  padding: ${p => p.theme.space[8]} ${p => p.theme.space[7]}
    ${p => p.theme.space[10]};

  ${Heading} {
    align-items: center;
    color: ${p => p.theme.colors.purple[50]};
    display: flex;
    font-size: ${p => p.theme.fontSizes[4]};
    font-weight: normal;
    justify-content: center;
    margin-bottom: ${p => p.theme.space[8]};
    text-align: center;
  }

  ${p => p.theme.mediaQueries.tablet} {
    padding: ${p => p.theme.space[8]} ${p => p.theme.space[8]}
      ${p => p.theme.space[8]};
  }

  ${p => p.theme.mediaQueries.desktop} {
    margin: ${p => p.theme.space[9]} auto;
  }

  :after,
  :before {
    background: ${p => p.theme.colors.standardLine};
    content: "";
    height: 1px;
    left: 50%;
    max-width: 30rem;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
  }

  :after {
    bottom: 0;
    top: auto;
  }
`

export const Sign = styled(`span`)`
  align-items: center;
  background: ${p => p.theme.colors.primaryBackground};
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: 6rem;

  svg {
    color: ${p => p.theme.colors.purple[50]};
    height: ${p => p.theme.space[7]};
    width: ${p => p.theme.space[7]};
  }
`

export const Testimonials = styled(`div`)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${p => p.theme.mediaQueries.desktop} {
    flex-direction: row;
  }
`

export const Testimonial = styled(`div`)`
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  padding: ${p => p.theme.space[4]} 0;

  ${p => p.theme.mediaQueries.desktop} {
    max-width: 75%;
    padding: 0 ${p => p.theme.space[7]};
  }
`

export const Quote = styled(`blockquote`)`
  border: none;
  color: ${p => p.theme.colors.grey[60]};
  font-family: ${p => p.theme.fonts.heading};
  font-size: ${p => p.theme.fontSizes[3]};
  line-height: 1.2;
  margin: 0;
  padding: 0;
  padding-bottom: ${p => p.theme.space[5]};
  text-align: center;

  p:before,
  p:after {
    color: ${p => p.theme.colors.purple[50]};
    content: "“";
    display: inline-block;
    font-size: ${p => p.theme.fontSizes[4]};
    font-weight: bold;
    line-height: ${p => p.theme.fontSizes[3]};
    position: relative;
    transform: translate(-0.2em, 0);
  }

  p:after {
    content: "”";
    transform: translate(0.2em, 0);
  }
`

export const Credits = styled(`a`)`
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;

  p {
    margin: 0;
  }
`

export const Author = styled(`div`)`
  font-family: ${p => p.theme.fonts.heading};
  font-size: ${p => p.theme.fontSizes[2]};
  font-weight: bold;
`

export const Position = styled(`div`)`
  color: ${p => p.theme.colors.grey[50]};
  font-size: ${p => p.theme.fontSizes[1]};
  font-weight: normal;
  line-height: 1.2;
  margin-top: ${p => p.theme.space[1]};
`
